import React from "react";
import { Redirect, Link } from "react-router-dom";
// import image1 from "../../img/undraw_online_organizer_ofxm.svg";
import imgPreview from "../../img/app-preview.jpeg";
import Layout from "../Layout";
import FormSignIn from "../FormSignIn";
import { getAuth } from "firebase/auth";
import firebaseApp from "../../firebase";

const auth = getAuth(firebaseApp);

function PageHome({ isSignedIn }) {
  // Place in useEffect to check this condition before loading?
  if (isSignedIn) return <Redirect to="/mylists/lists" />;
  return (
    <div className="page-home">
      <section className="home-welcome">
        <div className="welcome-wrapper center">
          <div className="welcome">
            {/* <div className="logo">Easy Link</div> */}
            <div className="home-intro">
              <h2 className="title">Stay organized with your browsing.</h2>
              {/* <h2 class="title">A place for your bookmarks.</h2> */}
              <p class="site-description">
                Pebble List is a simple and <strong>free</strong> bookmark
                manager for organizing and sharing your favorite websites.
              </p>
            </div>
            {/* <FormSignIn auth={auth} /> */}
            <Link className="button button-primary ghost-button" to="/signin">
              <span className="button-text">
                Get Started{" "}
                <span className="material-icons">arrow_forward</span>
              </span>
            </Link>
          </div>
          {/* <div className="app-preview">
            <div className="image-container">
              <img
                src={imgPreview}
                alt="Pebble List App Preview"
                height="100%"
                width="100%"
              />
            </div>
          </div> */}
        </div>
      </section>
      {/* <section className="section-features">
          <div className="center">
            <div className="features-col-1">
              <div className="img-container">
                <img
                  src={image1}
                  alt="Collections"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
            <div className="features-col-2">
              <h3>What is Easy Link?</h3>
              <p>
                Easy Link is a bookmark manager for organizing and sharing lists
                of your favorite websites that centers in on simplicity and easy
                of use.
              </p>
            </div>
          </div>
        </section> */}
    </div>
  );
}

export default PageHome;
