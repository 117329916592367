import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import firebaseApp from "./firebase";
//=======
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  collectionGroup,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
//=======

import ProtectedRoute from "./components/common/protectedRoute";
import PageSignIn from "./components/pages/PageSignIn";
import MyLists from "./components/MyLists";
import PageShared from "./components/pages/PageShared";
import PageHome from "./components/pages/PageHome";
import PageDiscover from "./components/pages/PageDiscover";
import PageAccount from "./components/pages/PageAccount";
import PageProfile from "./components/pages/PageProfile";
import PageVerify from "./components/pages/PageVerify";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import { where, onSnapshot } from "firebase/firestore";
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

function App() {
  const [user, setUser] = useState({
    displayName: "",
    photoURL: "",
  });
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]); // lists
  const [listIds, setListIds] = useState([]);
  const [items, setItems] = useState([]); // links

  // ===================================
  // Set User
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      setIsSignedIn(!!user);
      if (user) {
        console.log("set user");
        getAndSetUser(auth.currentUser.uid);
      }
      setLoading(false);
      // }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  async function getAndSetUser(uid) {
    const userRef = doc(firestore, "users", uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const displayName = userSnap.data().displayName;
      const photoURL = userSnap.data().photoURL;
      const userToSet = {
        displayName: displayName,
        photoURL: photoURL,
      };
      setUser(userToSet);
    }
  }

  // const testQuery = query(
  //     collection(firestore, "lists", doc.id, "links")
  //   );
  //   const unRegLinks = onSnapshot(testQuery, (qSnap) => {
  //     qSnap.forEach((doc2) => {
  //       let testLink = doc2.data();
  //       testLink.id = doc2.id;
  //       testLinks.push(testLink);
  //     });
  //   });
  //   return () => unRegLinks();
  // });

  // =================================================
  // Getting user data if signed in

  useEffect(() => {
    if (isSignedIn) {
      // getAndSetUser(auth.currentUser.uid);

      // ==============
      // GET LISTS
      const listsQuery = query(
        collection(firestore, "lists"),
        where(`roles.${auth.currentUser.uid}`, "==", "owner")
      );

      const unRegList = onSnapshot(
        listsQuery,
        (querySnapshot) => {
          let lists = [];
          let ids = [];
          // Prevents a double fire when serverTimeStamp is run
          if (!querySnapshot.metadata.hasPendingWrites) {
            querySnapshot.forEach((doc) => {
              // Not all documents in the query are being request from the db every time
              let list = doc.data();
              list.id = doc.id;
              lists.push(list);
              ids.push(doc.id);
            });
            lists.sort(function (a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            setLists(lists);
            setListIds(ids);
          }
        },
        (error) => {
          console.log("Listener Error");
        }
      );
      return () => unRegList();
    }
  }, [isSignedIn]);

  // ===================================
  // Get Links

  useEffect(() => {
    let listIds = [];
    lists.forEach((l) => {
      listIds.push(l.id);
    });

    // Getting links once we have lists
    if (listIds.length > 0) {
      const linksQuery = query(
        collectionGroup(firestore, "links"),
        where("listId", "in", listIds),
        orderBy("createdAt")
      );
      const unRegLinks = onSnapshot(
        linksQuery,
        (querySnapshot) => {
          // if (!querySnapshot.metadata.hasPendingWrites) {

          // }
          let links = [];
          querySnapshot.forEach((doc) => {
            let link = doc.data();
            link.id = doc.id;
            links.push(link);
          });
          console.log("Setting links");
          setItems(links);
        },
        (error) => {
          console.log(error.message);
        }
      );
      return () => unRegLinks();
    }
  }, [listIds]);

  function handleSetUser(user) {
    setUser(user);
  }

  if (loading) {
    return <h2 className="app-loading">Loading...</h2>;
  } else {
    return (
      <Router>
        <div className="pebble" data-theme="default">
          <ToastContainer />
          <AppHeader isSignedIn={isSignedIn} auth={auth} />
          <main className="page-main">
            <Switch>
              {/* App Main */}
              {/* TODO: Each time we reach this route, new queries are made. Any time we move to and from account page and public page */}
              <ProtectedRoute
                path="/mylists"
                isSignedIn={isSignedIn}
                render={(props) => (
                  <MyLists
                    db={firestore}
                    items={items}
                    lists={lists}
                    auth={auth}
                    user={user}
                    {...props}
                  />
                )}
              />
              <Route
                path="/discover"
                children={<PageDiscover db={firestore} />}
              />
              {/* Public Page */}
              <Route
                path="/list/:id"
                children={<PageShared db={firestore} />}
              />
              <Route
                path="/profile/:displayname"
                children={<PageProfile db={firestore} />}
              />
              {/* Account Page */}
              <ProtectedRoute
                path="/account"
                isSignedIn={isSignedIn}
                render={(props) => (
                  <PageAccount user={user} auth={auth} {...props} />
                )}
              />
              {/* Sign in page */}
              <Route path="/signin">
                <PageSignIn
                  isSignedIn={isSignedIn}
                  db={firestore}
                  auth={auth}
                />
              </Route>
              {/* Email verification page */}
              <Route path="/verify">
                <PageVerify
                  db={firestore}
                  auth={auth}
                  onSetUser={handleSetUser}
                />
              </Route>
              {/* Home Page */}
              <Route path="/">
                <PageHome isSignedIn={isSignedIn} />
              </Route>
            </Switch>
          </main>
          <AppFooter />
        </div>
      </Router>
    );
  }
}

export default App;
