import React, { useState } from "react";

function FormAddToList({ itemContent, lists, onAddLink }) {
  const [listSelection, setListSelection] = useState({
    listValue: "",
  });

  function handleChange({ currentTarget: input }) {
    let newSelection = { ...listSelection };
    newSelection[input.name] = input.value;
    setListSelection(newSelection);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let newLink = { content: itemContent };
    onAddLink(listSelection.listValue, newLink);
  }

  return (
    <div className="move-link-to-list">
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="listSelect">Add this link to another list</label>
          <select
            name="listValue"
            id="listSelect"
            value={listSelection.value}
            onChange={handleChange}
          >
            <option value="">--</option>
            {/* TODO: double check the below if no lists are found */}
            {lists.map((i) => (
              <option key={i.id} value={i.id}>
                {i.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button className="button-reversed" type="submit">
            Add
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormAddToList;
