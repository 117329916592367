import React from "react";
import Item from "./item";
import ListItemsToolbar from "./listItemsToolbar";

// activeList is the id and name of the currently active list

// list: category: string, id: string, name: string, owner: string

function ListItems({
  items,
  list,
  onAddLink,
  onUpdateItem,
  onDeleteItem,
  onDeleteList,
  onDialog,
}) {
  return (
    <div className="list-items-component">
      <div className="list-wrapper">
        {list && (
          <ListItemsToolbar
            list={list}
            onAddLink={onAddLink}
            onUpdateItem={onUpdateItem}
            onDeleteList={onDeleteList}
            onDialog={onDialog}
          />
        )}
        <div className="list-items">
          {items.length > 0 &&
            items.map((i) => (
              <Item
                key={i.id}
                item={i}
                onUpdateItem={onUpdateItem}
                onDeleteItem={onDeleteItem}
                onDialog={onDialog}
              />
            ))}
        </div>
      </div>
      {/* onDialog, listId, list, onDeleteList */}
      {/* <menu className="list-options">
        <li>
          <button
            className="button"
            name="edit"
            onClick={() => onDialog({ type: "editList", listId: list.id })}
          >
            <span className="material-icons">create</span>
          </button>
        </li>
        <li>
          <button className="button">
            <span
              className="material-icons"
              name="share"
              onClick={() => onDialog({ type: "shareList", listId: list.id })}
            >
              ios_share
            </span>
          </button>
        </li>
        <li>
          <button className="button" onClick={() => onDeleteList(list.id)}>
            <span className="material-icons">delete_outline</span>
          </button>
        </li>
      </menu> */}
    </div>
  );
}

export default ListItems;
