import React from "react";

function ItemNotes({ title, notes }) {
  return (
    <div>
      <h5>{title}</h5>
      <p>{notes}</p>
    </div>
  );
}

export default ItemNotes;
