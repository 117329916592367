import React, { useState, useEffect } from "react";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import Joi from "joi";

function ListForm({
  list,
  category,
  categories,
  onAddList,
  onUpdateList,
  closeDialog,
}) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    tags: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
  });

  useEffect(() => {
    if (list) {
      setFormData({
        name: list.name,
        description: list.description,
        tags: list.tags,
      });
    }
  }, [list]);

  const schema = Joi.object({
    name: Joi.string().required().label("List Name"),
    description: Joi.string().allow(""),
    tags: Joi.string().allow(""),
  });

  function validate() {
    const options = { abortEarly: false };
    const { error } = schema.validate(formData, options);
    if (!error) return null;

    let errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();

    const errors = validate();
    setFormErrors(errors || {});
    if (errors) return;

    if (!list) {
      onAddList(formData);
    } else {
      onUpdateList(list.id, formData);
    }

    closeDialog();
  }

  function handleChange({ currentTarget: input }) {
    // let errorsClone = { ...errors };
    // const errorMessage = validateProperty(input);
    // if (errorMessage) errorsClone[input.name] = errorMessage;
    // else delete errors[input.name];

    let newData = { ...formData };
    newData[input.name] = input.value;
    setFormData(newData);
  }

  return (
    <React.Fragment>
      <h3>{!list ? "Create a new list" : "Edit list"}</h3>
      <form onSubmit={handleSubmit}>
        <Input
          name="name"
          value={formData.name}
          label="List Name"
          onChange={handleChange}
          error={formErrors.name}
        />
        <Textarea
          name="description"
          value={formData.description}
          label="Description"
          onChange={handleChange}
        />
        {/* <div className="field">
          <label htmlFor="field-public-tags">Tags (optional)</label>
          <input
            type="text"
            name="tags"
            id="field-public-tags"
            value={data.tags}
            onChange={handleChange}
          />
        </div> */}

        <div
          className="field"
          style={
            formData.category === "addNewUserCat"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <label htmlFor="newCategory">New Category</label>
          <input
            id="newCategory"
            type="text"
            name="newCategory"
            value={formData.newCategory}
            onChange={handleChange}
          />
          {/* {errors.value ? <p className="error">{errors.value}</p> : null} */}
        </div>
        <button className="button-primary">Save</button>
      </form>
    </React.Fragment>
  );
}

export default ListForm;
