import React, { useEffect, useState } from "react";
// import { getFunctions, httpsCallable } from "firebase/functions";
import { useParams, Link } from "react-router-dom";
import SharedItem from "../SharedItem";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { toast } from "react-toastify";

// const functions = getFunctions();
// const getSharedItems = httpsCallable(functions, 'getSharedItems');

function PageShared({ db }) {
  const [data, setData] = useState({ list: {}, links: [] });
  // const [userLink, setUserLink] = useState("");

  let { id } = useParams();

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  // useEffect(() => {
  //   if (data.list.roles) {
  //     const uid = getKeyByValue(data.list.roles, "owner");
  //     setUserLink(uid);
  //   }
  // }, [data.list.roles]);

  useEffect(() => {
    if (id) {
      async function fetchData() {
        try {
          // Get list
          const docRef = doc(db, "lists", id);
          const docSnap = await getDoc(docRef);
          // if the document has not been shared cancel operation
          if (docSnap.data().visibility === "private") return;
          let queryLinks = [];
          // If it exists
          if (docSnap.exists()) {
            // Get it's links sub-collection
            const querySnapshot = await getDocs(
              collection(db, "lists", id, "links")
            );
            querySnapshot.forEach((doc) => {
              let link = doc.data();
              link.id = doc.id;
              queryLinks.push(link);
            });
            // Populate data to state
            setData({ list: docSnap.data(), links: queryLinks });
            // getKeyByValue(data.list.roles, "owner");
            // console.log(data.list.roles);
          }
        } catch (err) {
          return;
        }
      }
      fetchData();
    }
  }, [id]);

  return (
    <div className="page-shared">
      <section className="section-list-shared">
        {data.list && Object.keys(data.list).length > 0 && (
          <React.Fragment>
            <div className="shared-list-header">
              <div className="center">
                <div className="shared-list-top">
                  <h2 className="title">{data.list.name}</h2>
                  {data.list.description && <p>{data.list.description}</p>}
                </div>
                {data.list.createdBy.displayName && (
                  <div className="shared-by">
                    <small>Shared by</small>
                    <br />
                    <Link to={`/profile/${data.list.createdBy.displayName}`}>
                      {data.list.createdBy.displayName}
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="list-items-container">
              <div className="list-items">
                {data.links.map((item) => (
                  <SharedItem key={item.id} item={item} />
                ))}
              </div>
            </div>
          </React.Fragment>
        )}
        {data.list && Object.keys(data.list).length === 0 && (
          <p>Nothing here to display...</p>
        )}
      </section>
    </div>
  );
}

export default PageShared;
