import React from "react";

function Input({ name, label, value, error, onChange }) {
  return (
    <div className="field">
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        type="text"
        onChange={onChange}
        value={value}
      />
      {error && (
        <p className="field-error">
          <span className="material-icons">error</span> {error}
        </p>
      )}
    </div>
  );
}

export default Input;
