import React from "react";

function Textarea({ name, label, value, onChange }) {
  return (
    <div className="field">
      <label htmlFor={name}>{label}</label>
      <textarea
        name={name}
        id={name}
        onChange={onChange}
        value={value}
        cols="30"
        rows="4"
      ></textarea>
    </div>
  );
}

export default Textarea;
