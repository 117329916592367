import React from "react";

function Item({ item, onUpdateItem, onDeleteItem, onDialog }) {
  function setString(string) {
    const stringLimit = 65;
    if (string.length > stringLimit)
      return string.substring(0, 65).concat("...");
    return string;
  }

  return (
    <div className="item">
      <a
        href={item.content.url}
        target="_blank"
        rel="noopener noreferrer"
        className={getItemClasses()}
      >
        <div className="item-image-wrapper">
          <div
            className={item.content.img ? `item-image` : `item-image no-image`}
          >
            {item.content.img && (
              <img src={item.content.img} alt="Preview image for link" />
            )}
          </div>
        </div>
        <div className="item-info">
          <h4 className="title">
            {item.customTitle ? item.customTitle : item.content.title}
          </h4>
          <small>{item.content.domain}</small>
        </div>
      </a>
      <ul className="item-actions">
        <li>
          <button
            className={getItemClasses()}
            onClick={() =>
              onUpdateItem(item.listId, item.id, { highlight: !item.highlight })
            }
          >
            <span className="material-icons">star</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => onDialog({ type: "editItem", itemId: item.id })}
          >
            <span className="material-icons">edit</span>
          </button>
        </li>
        <li>
          <button onClick={() => onDeleteItem(item.id, item.listId)}>
            <span className="material-icons">delete</span>
          </button>
        </li>
      </ul>
    </div>
  );

  function getItemClasses() {
    let itemClasses = "";
    itemClasses += item.highlight ? "highlight" : "";
    return itemClasses;
  }
}

export default Item;
