import React from "react";
import NewLink from "./NewLink";
import { toast } from "react-toastify";

function ListItemsToolbar({
  list,
  onDeleteList,
  onDialog,
  onAddLink,
  onUpdateItem,
}) {
  function convertTime(seconds) {
    let newDate = new Date(seconds * 1000);
    return newDate.toDateString();
  }

  function handleCopyLink() {
    try {
      navigator.clipboard.writeText(`https://pebblelist.com/list/${list.id}`);
      toast.success("Share link copied to clipboard.", { autoClose: 2000 });
    } catch (err) {
      toast.error("Oops, copying the link didn't seem to work!");
    }
  }

  return (
    <div className="list-items-toolbar">
      <div className="toolbar-top">
        <h3 className="title">
          {list.name}{" "}
          {list.visibility === "shared" && (
            <span className="flag-shared">
              <button className="button-ui" onClick={() => handleCopyLink()}>
                <span className="material-icons">link</span>
              </button>
            </span>
          )}
        </h3>
        <button
          className="button-as-link new-list"
          onClick={() => onDialog({ type: "newItem", listId: list.id })}
        >
          + New Link
        </button>
        {/* <NewLink
          listId={list.id}
          onAddLink={onAddLink}
          onUpdateItem={onUpdateItem}
        /> */}
      </div>
      <div className="toolbar-bottom">
        <menu className="list-options">
          <li>
            <button
              className="button"
              name="edit"
              onClick={() => onDialog({ type: "editList", listId: list.id })}
            >
              <span className="material-icons">create</span>
            </button>
          </li>
          <li>
            <button className="button">
              <span
                className="material-icons"
                name="share"
                onClick={() => onDialog({ type: "shareList", listId: list.id })}
              >
                ios_share
              </span>
            </button>
          </li>
          <li>
            <button className="button" onClick={() => onDeleteList(list.id)}>
              <span className="material-icons">delete_outline</span>
            </button>
          </li>
        </menu>
      </div>
      {/* <small>
        Last Updated
        <br />
        {list.lastUpdated && convertTime(list.lastUpdated.seconds)}
      </small> */}
    </div>
  );
}

export default ListItemsToolbar;
