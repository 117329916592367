import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

function NewLink({ listId, onAddLink, onUpdateItem, closeDialog }) {
  const [data, setData] = useState({ text: "", customTitle: "" });
  const [formErrors, setFormErrors] = useState({
    name: "",
  });

  const functions = getFunctions();
  const getURL = httpsCallable(functions, "getURL");

  function handleChange({ currentTarget: input }) {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  }

  async function handleSubmit(e) {
    // onBlur, on enter keypress, set link button clicked
    e.preventDefault();
    if (data.text.length === 0) return;
    // Look for a link
    let protocolPattern = RegExp(/https?:\/\/|www./g);
    let protocolMatch = protocolPattern.exec(data.text);

    if (protocolMatch) {
      // continue -- found protocol
      let startIndex = protocolMatch.index;
      let endIndex = data.text.length;
      let newLink = data.text.substring(startIndex, endIndex);

      // If no http was found in the link, add it
      if (
        newLink.indexOf("http://") === -1 &&
        newLink.indexOf("https://") === -1
      ) {
        newLink = `https://${newLink}`;
      }

      // If the new link matches the old link, don't do anything
      // if (newLink === data.text) {
      //   console.log("returning");
      //   return;
      // }

      // Process URL
      // Define new item to add
      let newItem = {};

      newItem.content = {
        url: newLink,
        domain: "Loading...",
        title: "Loading...",
        desc: "",
        img: "",
      };
      // newItem.content = link.url ? link : {};
      try {
        // TODO: move processURL to handleAddItem
        const itemId = await onAddLink(listId, newItem);
        // await onAddLink(newItem, listId);
        processUrl(newLink, listId, itemId);
        closeDialog();
      } catch (err) {
        console.log(`Error: ${err.message}`);
      }
    } else {
      console.log("protocol match not found");
      // Try using a link or let us know whats wrong!
      return;
    }
  }

  async function processUrl(url, listId, itemId) {
    // eslint-disable-next-line
    const pattern = RegExp(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
    );

    if (pattern.test(url)) {
      // setLoading(true);
      try {
        // setLink({ url: url });
        const response = await getURL(url);
        // setLoading(false);
        // setLink(response.data);
        let itemContent = { content: response.data };
        setData({ text: "" });
        onUpdateItem(listId, itemId, itemContent);
      } catch (err) {
        console.log("Error Happened: " + err);
        return false;
      }
      // onGetURL(u
    } else {
      console.log("URL didn't pass");
      return;
    }
  }

  return (
    <form className="form-url" onSubmit={handleSubmit}>
      <div className="field" style={{ marginBottom: "0" }}>
        <input
          id="text"
          name="text"
          type="text"
          value={data.text}
          onChange={handleChange}
          autoComplete="off"
          placeholder="URL"
        />
      </div>
      <button className="button-primary">
        <span className="material-icons">add</span>
      </button>
    </form>
  );
}

export default NewLink;
