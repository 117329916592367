import React from "react";
import { Link } from "react-router-dom";

function AppHeader({ isSignedIn, auth }) {
  return (
    <header className="page-header app-nav">
      <Link className="home" to="/">
        <h1 className="title">
          <span className="logo">PL Icon</span> Pebble List
        </h1>
      </Link>
      <ul>
        {isSignedIn && (
          <React.Fragment>
            <li>
              <Link to="/mylists/lists" className="button-nav">
                Lists
              </Link>
            </li>
            <li>
              <Link to="/mylists/find" className="button-nav">
                <span className="material-icons">search</span>
              </Link>
            </li>
            <li>
              <Link className="button account" to="/account">
                <span className="material-icons">account_circle</span>
              </Link>
            </li>
          </React.Fragment>
        )}
        {!isSignedIn && (
          <li>
            <Link className="button account" to="/signin">
              Sign Up
            </Link>
          </li>
        )}
      </ul>
    </header>
  );
}

export default AppHeader;
