import React from "react";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import { getAuth } from "firebase/auth";

const auth = getAuth();

function Layout(props) {
  return (
    <React.Fragment>
      <AppHeader auth={auth} />
      <main className="main">{props.children}</main>
      <AppFooter />
    </React.Fragment>
  );
}

export default Layout;
