import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDocs, collection, query, where } from "firebase/firestore";

function PageProfile({ db }) {
  const [data, setData] = useState({ username: "" });
  const [loading, setLoading] = useState(true);

  let { displayname } = useParams();

  useEffect(() => {
    // if (loading) {
    async function fetchData() {
      try {
        // Get list
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("displayName", "==", displayname));
        // const docRef = doc(db, "users", id);
        const querySnapshot = await getDocs(q);
        let user = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          user.push(doc.data());
        });
        if (user[0].displayName) {
          setData({ username: user[0].displayName });
        }
      } catch (err) {
        console.log(err.message);
        return;
      }
    }
    fetchData();
    // }
  }, [displayname]);

  return (
    <section className="section-single">
      <h2>Public Profile</h2>
      <p>username: {data.username}</p>
    </section>
  );
}

export default PageProfile;
