import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";
import Input from "../common/Input";
import Joi from "joi";

function PageVerify({ db, auth, onSetUser }) {
  const [redirect, setRedirect] = useState("");

  // TODO: Use component inheritance to hold username and photoUrl around the app?

  useEffect(() => {
    // If user is signing in with email
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }

      // Sign user in
      signInUser(email);
    } else {
      // If user entered page randomly
      // redirect home
      setRedirect("/signin");
    }

    async function signInUser(email) {
      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );

      // Clear email from storage.
      window.localStorage.removeItem("emailForSignIn");

      // if new user
      if (result._tokenResponse.isNewUser) {
        addUserToDB(result);
        return;
      } else {
        // If not a new user
        // verify they exist in the db before continuing
        try {
          const docRef = doc(db, "users", result.user.uid);
          const docSnap = await getDoc(docRef);
          // IF user is NOT in DB
          if (!docSnap.exists()) {
            //  Try adding them to the DB
            addUserToDB(result);
          }
          return;
        } catch (err) {
          //  if that fails sign them out
          try {
            await signOut(auth);
            setRedirect("/signin");
          } catch (err) {
            if (err.message) {
              toast.error(`An error occurred signing user out: ${err.message}`);
            } else {
              toast.error("An unexpected error occurred.");
            }
          }
          if (err.message) {
            toast.error(
              `An error occurred getting or adding a new user: ${err.message}`
            );
          } else {
            toast.error("An unexpected error occurred.");
          }
        }
      }
    }
  }, [auth]);

  async function addUserToDB(result) {
    let newUser = {};

    const user = result.user;

    newUser.displayName = user.displayName ? user.displayName : "Anonymous";
    newUser.photoURL = user.photoURL ? user.photoURL : "Profile photo";
    newUser.signupDate = serverTimestamp();
    newUser.theme = "default";
    newUser.type = 1;

    try {
      const docRef = doc(db, "users", user.uid);
      await setDoc(docRef, newUser, { merge: true });
      onSetUser({
        isSignedIn: true,
        displayName: newUser.displayName,
        photoURL: newUser.photoURL,
      });
      setRedirect("/mylists/lists");
    } catch (err) {
      await signOut(auth);
      setRedirect("/signin");

      if (err.message) {
        toast.error("An error occurred adding a new user");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return <div className="page-verify"></div>;
}

export default PageVerify;
