import React from "react";
import ListItems from "../listItems";

function ListView({
  items,
  lists,
  onAddLink,
  onUpdateItem,
  onDeleteItem,
  onDeleteList,
  onDialog,
}) {
  function filterItems(listId) {
    // Is it really neccessary to filter items AND lists?
    let newItems = [];
    items.forEach((i) => {
      if (i.listId === listId) {
        newItems.push(i);
      }
    });
    return newItems;
  }

  return (
    <div className="list-view">
      <div className="list-view-wrapper-inside">
        {/* <div className="list-view-toolbar">
          <button
            className="button-primary"
            onClick={() => onDialog({ type: "newList", category: "websites" })}
          >
            + New List
          </button>
        </div> */}

        <div className="list-container">
          {lists.map((list) => (
            <div className="list" key={list.id}>
              <ListItems
                items={filterItems(list.id)}
                list={list}
                onAddLink={onAddLink}
                onUpdateItem={onUpdateItem}
                onDeleteItem={onDeleteItem}
                onDeleteList={onDeleteList}
                onDialog={onDialog}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListView;
