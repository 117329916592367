import React from "react";

const Dialog = ({ children, handleDialog, status }) => {
  
  function handleCloseDialog() {
    handleDialog(!status);
  }

  return (
    <div id="dialog" className="dialog-overlay">
      <div className="dialog-content">
        {React.cloneElement(children, { closeDialog: handleCloseDialog })}
        <button
          className="button-close dialog-close"
          onClick={handleCloseDialog}
        >
          <span className="material-icons">close</span>
        </button>
      </div>
    </div>
  );
};

export default Dialog;
