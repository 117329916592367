import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ViewBar from "./ViewBar";
import ItemsView from "./view-items/itemsView";
import FilterView from "./view-filters/FilterView";
import ListView from "./view-lists/listView";
import MyPublicLists from "./MyPublicLists";
import DialogContent from "./DialogContent";
import Dialog from "./common/dialog";
import { toast } from "react-toastify";
// import ListsView from "./view-lists/listView";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import PrivateLists from "./PrivateLists";

// TODO: Check use of asyncs - seems like I call them a lot when I might not need to if the root function uses it?

function MyLists({ auth, user, db, items, lists }) {
  const [active, setActive] = useState({ list: null }); // currently active list
  const [dialog, setDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  // Activate Category
  // function handleActivateCat(cat, listId) {
  //   let activate = { ...active };

  //   if (cat === "all" && lists.length > 0) {
  //     // set active list to whatever
  //     activate.list = lists[0].id;
  //     activate.category = null;
  //   } else {
  //     activate.category = cat;
  //     if (!listId) {
  //       // If no list id is provided, get ID of first list in that category
  //       if (!listId) {
  //         // will eventually need to make getting a list id more efficient
  //         let listToActivate = lists.filter((l) => l.category === cat);

  //         if (listToActivate.length > 0) {
  //           // As long as there's more than one item
  //           activate.list = listToActivate[0].id;
  //         } else {
  //           // No lists
  //           return;
  //         }
  //       } else {
  //         activate.list = listId;
  //       }
  //     }
  //   }

  //   setActive(activate);
  // }

  // =========================================
  // LIST - ADD
  async function handleAddList(data) {
    // data.name
    // data.description
    let list = { ...data };
    list.createdOn = serverTimestamp();
    list.createdBy = {
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
    list.visibility = "private";
    list.roles = { [auth.currentUser.uid]: "owner" };

    try {
      await addDoc(collection(db, "lists"), list);
    } catch (err) {
      if (err.message) {
        toast.error(`An error occurred adding a new list: ${err.message}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  // =========================================
  // LIST - UPDATE
  async function handleUpdateList(listId, data) {
    try {
      const listRef = doc(db, "lists", listId);
      data.lastUpdated = serverTimestamp();
      await updateDoc(listRef, data);
    } catch (err) {
      if (err.message) {
        toast.error(`An error occurred updating an item: ${err.message}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  // =========================================
  // LIST DELETE
  async function handleDeleteList(listId) {
    if (
      window.confirm(
        "Are you sure you want to delete this list? All containing links will be deleted."
      )
    ) {
      try {
        const batch = writeBatch(db);

        // Delete the list
        batch.delete(doc(db, "lists", listId));

        // Then delete each link within the list
        // TODO: Maybe check that links exist before trying this?
        const querySnapshot = await getDocs(
          collection(db, "lists", listId, "links")
        );
        querySnapshot.forEach((docu) => {
          const docRef = doc(db, "lists", listId, "links", docu.id);
          batch.delete(docRef);
        });

        // commit the batch delete
        await batch.commit();
      } catch (err) {
        if (err.message) {
          toast.error(`An error occurred deleting the list: ${err.message}`);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  }

  // =========================================
  // LINK - ADD
  async function handleAddLink(listId, item) {
    item.listId = listId;
    item.highlight = false;
    item.createdAt = serverTimestamp();

    try {
      const docRef = await addDoc(
        collection(db, "lists", listId, "links"),
        item
      );
      // const docRef = await addDoc(collection(db, "items"), item);
      const docId = docRef.id;
      console.log(docId);
      return docId;
    } catch (err) {
      if (err.message) {
        toast.error(`An error occurred adding a new item: ${err.message}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  // =========================================
  // LINK - UPDATE
  async function handleUpdateItem(listId, itemId, itemContent) {
    try {
      const listRef = doc(db, "lists", listId, "links", itemId);
      await updateDoc(listRef, itemContent);
    } catch (err) {
      if (err.message) {
        toast.error(`An error occurred updating the item: ${err.message}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  // =========================================
  // LINK - DELETE
  async function handleDeleteItem(itemId, listId) {
    if (window.confirm("Are you sure you want to delete this link?")) {
      try {
        await deleteDoc(doc(db, "lists", listId, "links", itemId));
      } catch (err) {
        if (err.message) {
          toast.error(`An error occurred deleting the item: ${err.message}`);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  }

  // function getCategories(arr) {
  //   var counts = {};
  //   for (var i = 0; i < arr.length; i++) {
  //     if (arr[i].category) {
  //       counts[arr[i].category] = 1 + (counts[arr[i].category] || 0);
  //     }
  //   }
  //   return Object.keys(counts);
  // }

  // Function to move user to a specific list
  // function handleMoveToList(filter, listId) {
  //   handleActivateCat(filter, listId);
  //   history.push("/main");
  // }

  function handleDialog(content) {
    // let inboundContentExample = {
    //   type: "newItem",
    //   listId: "id",
    // };
    setDialogContent(content);
    setDialog(!dialog);
  }

  const { path } = useRouteMatch();

  return (
    <div className="page">
      {/* <ViewBar onDialog={handleDialog} /> */}
      <Switch>
        {/* <Route path={`${path}/public`}>
          <MyPublicLists />
        </Route> */}
        <Route path={`${path}/find`}>
          <ItemsView
            items={items}
            onDeleteItem={handleDeleteItem}
            onDialog={handleDialog}
          />
        </Route>

        <Route path={`${path}/lists`}>
          {/* Move active list down */}
          <PrivateLists
            items={items}
            lists={lists}
            onAddLink={handleAddLink}
            onUpdateItem={handleUpdateItem}
            onDeleteItem={handleDeleteItem}
            onDeleteList={handleDeleteList}
            onDialog={handleDialog}
          />
        </Route>
        {/* <Route path={`${path}/unsorted`}>
          <ListView
            items={items}
            lists={lists}
            onAddLink={handleAddLink}
            onUpdateItem={handleUpdateItem}
            onDeleteItem={handleDeleteItem}
            onDeleteList={handleDeleteList}
            onDialog={handleDialog}
          />
        </Route>
        <Route path={`${path}/sorted`}>
          <FilterView
            items={items}
            lists={lists}
            active={active}
            onHandleActivate={handleActivateList}
            onAddLink={handleAddLink}
            onUpdateItem={handleUpdateItem}
            onDeleteItem={handleDeleteItem}
            onDeleteList={handleDeleteList}
            onDialog={handleDialog}
          />
        </Route> */}
      </Switch>
      {dialog && (
        <Dialog status={dialog} handleDialog={setDialog}>
          <DialogContent
            items={items}
            lists={lists}
            content={dialogContent}
            onUpdateItem={handleUpdateItem}
            onAddLink={handleAddLink}
            onAddList={handleAddList}
            onUpdateList={handleUpdateList}
          />
        </Dialog>
      )}
    </div>
  );
}

export default MyLists;
