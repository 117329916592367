import React from "react";

function AppFooter(props) {
  return (
    <footer className="page-footer">
      <small>&copy; {new Date().getFullYear()} - Pebble List</small>
    </footer>
  );
}

export default AppFooter;
