import React from "react";
import { Link } from "react-router-dom";

function PageAccount({ user, auth }) {
  return (
    <div className="page-profile">
      <div className="container">
        <section className="section-single">
          <div className="section-account">
            <header className="section-account-header">
              <span className="material-icons">account_circle</span>
              <h3>Account</h3>
            </header>
            <div className="account-details">
              <p>
                Username: <strong>{user.displayName}</strong>
              </p>
            </div>
            <div className="button-group">
              <Link
                className="button button-reversed"
                to={`/profile/${user.displayName}`}
              >
                Public Profile
              </Link>
              <button
                className="button-reversed"
                onClick={() => auth.signOut()}
              >
                Sign-out
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PageAccount;
