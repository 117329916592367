import React from "react";

function List({ list, active, onHandleActivate }) {
  return (
    <li className="list">
      <button
        className={
          list.id === active.list
            ? "list-button-large active"
            : "list-button-large"
        }
        key={list.id}
        onClick={() => onHandleActivate(list.id)}
      >
        {list.name}
        {/* {list.visibility === "shared" && (
          <span className="material-icons">link</span>
        )} */}
      </button>
    </li>
  );
}

export default List;
