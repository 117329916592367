import React from "react";

function AttachedLink({ link }) {
  return (
    <div className="attached-content">
      {/* {loading && (
        <div className="link-loading">
          <p>Retrieving URL...</p>
        </div>
      )} */}
      {link.url && (
        <React.Fragment>
          {/* <small>Attached content</small> */}
          <div className="attached-link">
            {link.img && (
              <div className="attached-link-img">
                <img src={link.img} alt="Link" height="100%" width="100%" />
              </div>
            )}
            <div className="attached-link-copy">
              <p>
                <strong>{link.title}</strong>
              </p>
              <small>{link.domain}</small>
            </div>
            {/* <button className="ui-button ui-button-clear" onClick={onClearLink}>
              <span className="material-icons">clear</span>
            </button> */}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default AttachedLink;
