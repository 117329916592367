import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  isSignedIn,
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isSignedIn) return <Redirect to="/signin" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
