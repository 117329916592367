import React, { useState, useEffect } from "react";
import FilterView from "./view-filters/FilterView";
import ListView from "./view-lists/listView";

function PrivateLists(props) {
  const [view, setView] = useState(1);
  const [active, setActive] = useState({ list: null }); // currently active list

  useEffect(() => {
    if (props.lists.length > 0 && !active.list) {
      handleActivateList(props.lists[0].id);
    }
  }, [props.lists]);

  function handleActivateList(id) {
    setActive({ list: id });
  }

  let content;

  switch (view) {
    case 1:
      content = (
        <FilterView
          {...props}
          active={active}
          onHandleActivate={handleActivateList}
        />
      );
      break;
    case 2:
      content = <ListView {...props} />;
      break;
    default:
      content = <p>No View selected</p>;
      break;
  }

  return (
    <React.Fragment>
      <menu className="view-bar">
        <li>
          <button
            onClick={() => setView(1)}
            className={view === 1 ? "button-nav active" : "button-nav"}
          >
            <span className="material-symbols-outlined">sort</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => setView(2)}
            className={view === 2 ? "button-nav active" : "button-nav"}
          >
            <span className="material-symbols-outlined">grid_view</span>
          </button>
        </li>
      </menu>
      {content}
    </React.Fragment>
  );
}

export default PrivateLists;
