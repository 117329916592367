import React from "react";
import ListForm from "./forms/listForm";
import EditItem from "./forms/EditItem";
import ListSharingForm from "./forms/listSharingForm";
import NewLink from "./NewLink";

function DialogContent(props) {
  let item, list;

  switch (props.content.type) {
    case "newItem":
      // list = props.lists.filter((l) => l.id === props.content.listId)[0];
      return (
        <NewLink
          listId={props.content.listId}
          onAddLink={props.onAddLink}
          onUpdateItem={props.onUpdateItem}
          closeDialog={props.closeDialog}
        />
      );

    case "editItem":
      item = props.items.filter((i) => i.id === props.content.itemId)[0]; // gets the item matching the id provided

      return (
        <EditItem
          item={item}
          lists={props.lists}
          onAddLink={props.onAddLink}
          onUpdateItem={props.onUpdateItem}
          closeDialog={props.closeDialog}
        />
      );
    // case "editItemLists":
    //   item = props.items.filter((i) => i.id === props.content.itemId)[0];
    //   return (
    //     <EditListsForm
    //       item={item}
    //       lists={props.lists}
    //       onRemoveFromList={props.onRemoveFromList}
    //       onAddToList={props.onAddToList}
    //       closeDialog={props.closeDialog}
    //     />
    //   );
    case "newList":
      return (
        <ListForm
          category={props.content.category}
          categories={props.categories}
          onAddList={props.onAddList}
          closeDialog={props.closeDialog}
        />
      );
    case "editList":
      list = props.lists.filter((l) => l.id === props.content.listId)[0];
      return (
        <ListForm
          list={list}
          categories={props.categories}
          onUpdateList={props.onUpdateList}
          closeDialog={props.closeDialog}
        />
      );
    case "shareList":
      function filterItems(listId) {
        // get each item where it's listId == the listId provided
        let filteredItems = [];
        props.items.forEach((i) => {
          if (i.listId === listId) {
            filteredItems.push(i);
          }
        });
        return filteredItems;
      }

      list = props.lists.filter((l) => l.id === props.content.listId)[0];
      let listItems = filterItems(list.id);
      return (
        <ListSharingForm
          list={list}
          listItems={listItems}
          onUpdateList={props.onUpdateList}
          onAddSharedCopy={props.onAddSharedCopy}
          onRemoveSharedCopy={props.onRemoveSharedCopy}
          closeDialog={props.closeDialog}
        />
      );
    default:
      // Catch all
      // Any other content like messeages
      return <div>{props.content}</div>;
  }
}

export default DialogContent;
