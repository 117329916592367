import React, { useState, useEffect } from "react";
import Item from "../item";

function ItemsView({ items, onDeleteItem, onDialog }) {
  const [data, setData] = useState({
    search: "",
  });
  const [filteredLinks, setFilteredLinks] = useState([]);

  useEffect(() => {
    filterLinks(items);
  }, [data.search]);

  function filterLinks(items) {
    let links = items.filter((i) =>
      i.content.title.toLowerCase().includes(data.search.toLocaleLowerCase())
    );
    setFilteredLinks(links);
  }

  function handleChange({ currentTarget: input }) {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  }

  return (
    <div className="items-view">
      <div className="container">
        <div className="list-items-component">
          <div className="list-items-toolbar">
            <h2 className="title">Find Links</h2>
            <div className="field" style={{ marginBottom: 0 }}>
              <input
                id="search"
                name="search"
                type="text"
                placeholder="Search Links"
                value={data.search}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="list-items">
            {filteredLinks.length > 0 ? (
              filteredLinks.map((i) => {
                return (
                  <Item
                    key={i.id}
                    item={i}
                    onDeleteItem={onDeleteItem}
                    onDialog={onDialog}
                  />
                );
              })
            ) : (
              <p>Nothing to display</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemsView;
