import React from "react";
import List from "./list";

function Lists({ lists, active, onDialog, onHandleActivate }) {
  return (
    <React.Fragment>
      <div className="lists">
        <div className="lists-toolbar">
          <h3 className="title">Lists</h3>
          <button
            className="button-as-link new-list"
            onClick={() =>
              onDialog({ type: "newList", category: active.category })
            }
          >
            <span className="material-icons">add</span>New List
          </button>
        </div>
        <menu className="lists-lists">
          {lists.map((list) => (
            <List
              key={list.id}
              list={list}
              active={active}
              onHandleActivate={onHandleActivate}
            />
          ))}
        </menu>
      </div>
    </React.Fragment>
  );
}

export default Lists;
