import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");
// const firestore = firebase.firestore();

const firebaseConfig = {
  apiKey: "AIzaSyAq7CqKYA43O8WCA71i7tyQzcIud7wS3sM",
  authDomain: "pebblelist.com",
  databaseURL: "https://eiger-4d7b5.firebaseio.com",
  projectId: "eiger-4d7b5",
  storageBucket: "eiger-4d7b5.appspot.com",
  messagingSenderId: "515257414701",
  appId: "1:515257414701:web:5f5116921b9ecaa73234a4",
  measurementId: "G-PM3MD1BMB4",
};

// firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
initializeAnalytics(firebaseApp);
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider("6LeFS4sfAAAAAI4J1fMm68h7zvq8ilx7oyRdRYJP"),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

export default firebaseApp;
