import React from "react";
import { toast } from "react-toastify";

function ListSharingForm({
  list,
  listItems,
  onUpdateList,
  onAddSharedCopy,
  onRemoveSharedCopy,
}) {
  function handleChange({ currentTarget: input }) {
    if (input.value === "private" || "shared" || "public") {
      onUpdateList(list.id, { visibility: input.value });
    }
  }

  function handleCopyLink() {
    try {
      navigator.clipboard.writeText(`https://pebblelist.com/list/${list.id}`);
      toast.success("Link copied to clipboard.", { autoClose: 2000 });
    } catch (err) {
      toast.error(
        "Oops, copying the link didn't seem to work! Perhaps copy the link manually."
      );
    }
  }

  return (
    <div className="list-sharing">
      <h4>List Visibility</h4>
      {/* <div className="field-share">
          <input
            id="share"
            name="share"
            type="checkbox"
            onChange={handleChange}
            checked={list.visibility === "private" ? false : true}
          />
          <label htmlFor="share">
            <span>Share list</span>
          </label>
        </div> */}

      <div className="field">
        <div className="share-option">
          <label htmlFor="vis-private">Private</label>
          <input
            type="radio"
            id="vis-private"
            name="visibility"
            value="private"
            onChange={handleChange}
            checked={list.visibility === "private"}
          />
        </div>
        <div className="share-option">
          <label htmlFor="vis-shared">Shared Link</label>
          <input
            type="radio"
            id="vis-shared"
            name="visibility"
            value="shared"
            onChange={handleChange}
            checked={list.visibility === "shared"}
          />
        </div>
        {/* <div className="share-option">
          <label htmlFor="vis-public">Public</label>
          <input
            type="radio"
            id="vis-public"
            name="visibility"
            value="public"
            onChange={handleChange}
            checked={list.visibility === "public"}
          />
        </div> */}
      </div>

      {list.visibility === "shared" && (
        <React.Fragment>
          <div className="list-sharing-url">
            <div className="field">
              <input value={`pebblelist.com/list/${list.id}`} readOnly={true} />
              <button className="button-ui" onClick={handleCopyLink}>
                <span className="material-icons">link</span>
              </button>
            </div>
            <small className="note">
              NOTE: Anyone with this link can see this list
            </small>
          </div>
        </React.Fragment>
      )}
      {list.visibility === "public" && (
        <React.Fragment>
          <p>Your list is now public</p>
          <div className="list-sharing-url">
            <div className="field">
              <input value={`pebblelist.com/list/${list.id}`} readOnly={true} />
              <button className="button-ui" onClick={handleCopyLink}>
                <span className="material-icons">link</span>
              </button>
            </div>
            <small className="note">
              NOTE: Anyone with this link can see this list
            </small>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default ListSharingForm;
