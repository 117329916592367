import React, { useState } from "react";
import Dialog from "./common/dialog";
import ItemNotes from "./ItemNotes";

function SharedItem({ item }) {
  const [dialog, setDialog] = useState(false);

  function setString(string) {
    const stringLimit = 65;
    if (string.length > stringLimit)
      return string.substring(0, 65).concat("...");
    return string;
  }

  return (
    <div className="item shared-item">
      <a
        href={item.content.url}
        target="_blank"
        rel="noopener noreferrer"
        className={getItemClasses()}
      >
        <div className="item-image-wrapper">
          <div
            className={item.content.img ? `item-image` : `item-image no-image`}
            style={
              item.content.img
                ? {
                    backgroundImage: `url(${item.content.img})`,
                  }
                : {}
            }
          ></div>
        </div>
        <div className="item-info">
          <h5 className="title">
            {item.customTitle
              ? setString(item.customTitle)
              : setString(item.content.title)}
          </h5>
          {item.linkNotes && <p>{item.linkNotes}</p>}
          <small>{item.content.domain}</small>
        </div>
      </a>
      {/* {item.linkNotes && (
        <menu className="item-actions">
          <li>
            <button onClick={() => setDialog(!dialog)}>
              <span className="material-icons">description</span>
            </button>
          </li>
        </menu>
      )} */}

      {dialog && (
        <Dialog status={dialog} handleDialog={setDialog}>
          <ItemNotes title={item.content.title} notes={item.linkNotes} />
        </Dialog>
      )}
    </div>
  );
  function getItemClasses() {
    let itemClasses = "";
    itemClasses += item.highlight ? "highlight" : "";
    return itemClasses;
  }
}

export default SharedItem;
