import React, { useState, useEffect } from "react";
import AttachedLink from "../attachedLink";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import FormAddToList from "./FormAddToList";

function EditItem({ item, lists, onAddLink, onUpdateItem, closeDialog }) {
  const [data, setData] = useState({
    text: "",
    customTitle: "",
    linkNotes: "",
    selectedList: "",
  });

  // effect to see if an item was passed (for editing items)
  useEffect(() => {
    if (item) {
      let obj = {
        text: item.content.url,
        customTitle: item.customTitle ? item.customTitle : "",
        linkNotes: item.linkNotes ? item.linkNotes : "",
      }; // The condititional here is to support older items that don't have a customTitle key
      setData(obj);
    }
  }, [item]);

  function handleChange({ currentTarget: input }) {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  }

  function handleSave(e) {
    // When submit button is clicked
    e.preventDefault();
    // Updating an existing item
    let updatedItem = { ...item };
    // TODO: Make sure we aren't mutating the state below
    updatedItem.customTitle = data.customTitle;
    updatedItem.linkNotes = data.linkNotes;

    // updatedItem.content = link;
    onUpdateItem(item.listId, item.id, updatedItem);
    closeDialog();
  }

  return (
    <div className="form-container edit-item">
      <h3>Edit Item</h3>
      <AttachedLink link={item.content} />
      <div className="item-custom-title">
        <Input
          name="customTitle"
          value={data.customTitle}
          label="Custom title"
          onChange={handleChange}
        />
      </div>
      <div className="item-notes">
        <Textarea
          name="linkNotes"
          value={data.linkNotes}
          label="Link notes"
          onChange={handleChange}
        />
      </div>
      <div className="lists">
        <FormAddToList
          itemContent={item.content}
          lists={lists}
          onAddLink={onAddLink}
        />
      </div>
      <button className="button-primary" onClick={handleSave}>
        Save
      </button>
    </div>
  );
}

export default EditItem;
