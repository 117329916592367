import React from "react";
import Lists from "./lists";
import ListItems from "../listItems";

function FilterView({
  items,
  lists,
  active,
  onHandleActivate,
  onAddLink,
  onUpdateItem,
  onDeleteItem,
  onDeleteList,
  onDialog,
}) {
  // Filter data ============================
  function filterItems(listId) {
    // Is it really neccessary to filter items and lists?
    let filteredItems = [];
    items.forEach((i) => {
      if (i.listId === listId) {
        filteredItems.push(i);
      }
    });
    return filteredItems;
  }

  function filterLists(listId) {
    // needs updating
    const newList = lists.filter((l) => l.id === active.list)[0];
    return newList;
  }

  function filterCategory(cat) {
    if (cat) {
      const listSet = lists.filter((l) => l.category === active.category);
      return listSet;
    } else {
      return lists;
    }
  }

  return (
    <div className="filter-view">
      {/* <div className="sub-nav">
        <Categories
          categories={categories}
          active={active.category}
          onActivateCat={onActivateCat}
        />
      </div> */}
      <div className="app">
        <section className="lists-column">
          <Lists
            lists={lists}
            active={active}
            onDialog={onDialog}
            onHandleActivate={onHandleActivate}
          />
        </section>
        <section className="list-items-column">
          {lists.length > 0 && (
            <ListItems
              items={filterItems(active.list)}
              list={filterLists(active.list)}
              onAddLink={onAddLink}
              onUpdateItem={onUpdateItem}
              onDeleteItem={onDeleteItem}
              onDeleteList={onDeleteList}
              onDialog={onDialog}
            />
          )}
        </section>
      </div>
    </div>
  );
}

export default FilterView;
