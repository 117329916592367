import React, { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { sendSignInLinkToEmail, signOut } from "firebase/auth";
import Input from "../common/Input";
import Joi from "joi";

import firebase from "firebase/compat/app";
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";

function PageSignIn({ db, auth, isSignedIn }) {
  const [data, setData] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({ email: "" });
  const [emailSent, setEmailSent] = useState(false);

  const schema = Joi.object({
    // username: Joi.string().required().min(6).max(30),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  });
  // const schema = Joi.object({
  //   name: Joi.string().required().label("List Name"),
  //   description: Joi.string().allow(""),
  //   tags: Joi.string().allow(""),
  // });

  function validate() {
    const options = { abortEarly: false };
    const { error } = schema.validate(data, options);
    if (!error) return null;

    let errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // Validation
    const errors = validate();
    setFormErrors(errors || {});
    if (errors) return;
    // end validation

    // Check for username existence
    // const displayNameQuery = query(
    //   collection(db, "users"),
    //   where("displayName", "==", data.username)
    // );

    // const querySnapshot = await getDocs(displayNameQuery);

    // querySnapshot.forEach((doc) => {
    //   if (doc.exists()) {
    //     const error = { username: "Username already exists" };
    //     setFormErrors(error);
    //   }
    // });
    // return;

    let actionCodeSettings = {
      url: "https://pebblelist.com/verify",
      // url: "http://localhost:3000/verify",
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, data.email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", data.email);
      setEmailSent(true);
    } catch (err) {
      if (err.message) {
        toast.error(
          `An error occurred sending the sign in email: ${err.message}`
        );
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  function validateProperty({ name, value }) {
    const field = { [name]: value };
    const extractedRule = schema.extract(name);
    const fieldSchema = Joi.object({ [name]: extractedRule });
    const { error } = fieldSchema.validate(field);
    return error ? error.details[0].message : null;
  }

  function handleChange({ currentTarget: input }) {
    let errors = { ...formErrors };
    const errorMessage = validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
    setFormErrors(errors);
  }

  async function handleAddUserToDB(user) {
    let newUser = {};

    newUser.displayName = user.displayName ? user.displayName : "Anonymous";
    newUser.photoURL = user.photoURL ? user.photoURL : "Profile Photo";
    newUser.signupDate = serverTimestamp();
    newUser.theme = "default";
    newUser.type = 1;

    try {
      const docRef = doc(db, "users", user.uid);
      await setDoc(docRef, newUser, { merge: true });
    } catch (err) {
      await signOut(auth);

      if (err.message) {
        toast.error("An error occurred adding a new user");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  const uiconfig = {
    callbacks: {
      signInSuccessWithAuthResult: async function (authResult, redirectUrl) {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        let user = authResult.user;
        let isNewUser = authResult.additionalUserInfo.isNewUser;

        if (isNewUser) {
          handleAddUserToDB(user);
        } else {
          // TODO: Try throwing an error here to make sure the signOut happens
          try {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            // IF user is NOT in DB
            if (!docSnap.exists()) {
              //  Try adding them to the DB
              handleAddUserToDB(user);
            }
          } catch (err) {
            await signOut(auth);

            if (err.message) {
              toast.error(
                `An error occurred getting or adding a new user: ${err.message}`
              );
            } else {
              toast.error("An unexpected error occurred.");
            }
          }
        }

        return false;
      },
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: "popup",
    signInSuccessUrl: "",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    // tosUrl: "<your-tos-url>",
    // Privacy policy url.
    // privacyPolicyUrl: "<your-privacy-policy-url>",
  };

  if (isSignedIn) {
    return <Redirect to="/mylists/lists" />;
  } else {
    return (
      <div className="page-signin">
        <section className="section-signin">
          <h2 className="title">Sign In</h2>
          <div className="content-signin">
            {emailSent && (
              <p className="message-signin">
                Email confirmation has been sent, please verify your email to
                continue.
              </p>
            )}
            <form className="login-form" onSubmit={handleSubmit}>
              {/* <Input
                  name="username"
                  value={data.username}
                  onChange={handleChange}
                  label="Choose a username"
                  error={formErrors.username}
                /> */}
              <Input
                name="email"
                value={data.email}
                onChange={handleChange}
                label="Enter an email address"
                error={formErrors.email}
              />
              <button className="button button-tertiary" type="submit">
                Verify Email{" "}
                <span className="material-icons">arrow_forward</span>
              </button>
            </form>
            <p
              style={{
                textAlign: "center",
                marginTop: "1em",
                marginBottom: "0",
              }}
            >
              <b>OR</b>
            </p>
            <StyledFirebaseAuth uiConfig={uiconfig} firebaseAuth={auth} />
          </div>
        </section>
      </div>
    );
  }
}

export default PageSignIn;
